import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CalculadoraService } from 'src/app/calculadora-site/services/calculadora.service';

@Component({
  selector: 'app-calculate',
  templateUrl: './app-calculate.component.html',
  styleUrls: ['./app-calculate.component.scss']
})
export class AppCalculateComponent{

  sendOutputDataInterval;
  outOfAmountBounds = false;

  editMode = false;

  @Input() sliderValues;
  @Input() isMoney;
  @Output() outputData = new EventEmitter<any>();
  @Output() disableButton = new EventEmitter<boolean>();

  @ViewChild('inputEdit', { static: false }) inputEdit: ElementRef<HTMLInputElement>;

  constructor(
    private calculadoraService: CalculadoraService
  ) {}

  checkOutOfBounds(timeout?: number, ammountValue?: number) {
    this.checkIllegalCharsAtEditAmountInput();
    timeout = !!timeout ? timeout : 0;
    ammountValue = !!ammountValue ? this.numberTreatment(ammountValue.toString()) : +this.sliderValues.amount;
    if (!!this.sendOutputDataInterval) {
      clearInterval(this.sendOutputDataInterval);
    }
    if (
      this.editMode ?
        !!this.inputEdit.nativeElement.value &&
        !!ammountValue &&
        ammountValue >= +this.sliderValues.minAmount &&
        ammountValue <= +this.sliderValues.maxAmount
      :
        !!ammountValue &&
        ammountValue >= +this.sliderValues.minAmount &&
        ammountValue <= +this.sliderValues.maxAmount
    ) {

      this.outOfAmountBounds = false;
      this.calculadoraService.amountOutOfBounds$.next(false);
      this.sendOutputDataInterval = setInterval( () => {
        this.sendOutputData(ammountValue);
      }, timeout);

    } else {
      this.outOfAmountBounds = true;
      this.calculadoraService.amountOutOfBounds$.next(true);
    }
  }

  checkIllegalCharsAtEditAmountInput() {
    if (!!this.editMode) {
      this.inputEdit.nativeElement.value = this.numberTreatment(this.inputEdit.nativeElement.value).toString();
      if (this.inputEdit.nativeElement.value === '0' || isNaN(+this.inputEdit.nativeElement.value)) {
        this.inputEdit.nativeElement.value = '';
      }
    }
  }

  sendOutputData(ammountValue?) {
    if (ammountValue) {
      this.sliderValues.amount = ammountValue;
    }
    this.outOfAmountBounds = false;
    this.calculadoraService.amountOutOfBounds$.next(false);
    this.outputData.emit(this.sliderValues.amount);
    if (!!this.sendOutputDataInterval) {
      clearInterval(this.sendOutputDataInterval);
    }
  }

  numberTreatment(value: string) {
    return +Number((value.replace(/,/g, '.')).replace(/[^.0-9]/g, '')).toFixed(2);
  }

  removeEditMode() {
    if (!!this.sendOutputDataInterval) {
      clearInterval(this.sendOutputDataInterval);
    }
    this.editMode = false;
    this.checkOutOfBounds();
  }

  setValueToModel(qty) {
    this.sliderValues.amount = qty.value;
    this.disableButton.emit(true);
    this.checkOutOfBounds(1000);
  }

  setFocusToEditInput() {
    this.editMode = true;
    setTimeout( () => {
      this.inputEdit.nativeElement.focus();
      this.inputEdit.nativeElement.setSelectionRange(0, 0);
    }, 0);
  }
}
